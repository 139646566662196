import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import axios from 'axios'
import cn from 'classnames'
// local libs
import { apiURL, baseDomain } from 'src/config/api'
// styles
import css from './PayNavigator.module.scss'

type IpResponse = {
  ip: string
}

type TransactionResponse = {
  paymentURL: string
}

const pathRegex = /^\/payment\/([a-zA-Z0-9-]+)\/([a-zA-Z0-9-]+)\/?$/

const sendTransaction = async (
  paymentSystem: string,
  transactionId: ID,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
) => {
  try {
    const fp = await FingerprintJS.load()
    const { visitorId: fingerprint } = await fp.get()
    const {
      data: { ip: userIp },
    } = await axios.get<IpResponse>(`https://ip-resolver.${baseDomain}/`)
    const { userAgent } = window.navigator

    const data = {
      transactionId,
      fingerprint,
      userIp,
      userAgent,
    }

    const {
      data: { paymentURL },
    } = await axios.request<TransactionResponse>({
      url: `${apiURL}/api/pay-aggregator/${paymentSystem}/send`,
      method: 'POST',
      data,
    })

    if (!paymentURL) {
      throw Error('No paymentURL')
    }

    document.location = paymentURL
  } catch (err) {
    console.error(`Can't send transaction ${transactionId}:`, err)
    setIsLoading(false)
  }
}

const contactStore = <h1 className={cn(css.center, css.contactStore)}>Contact store</h1>

export const PayNavigator = () => {
  const [isLoading, setIsLoading] = useState(true)

  const path = document.location.pathname
  const pathMatch = path.match(pathRegex)
  const paymentSystem = pathMatch ? pathMatch[1] : null
  const transactionId = pathMatch ? pathMatch[2] : null

  useEffect(() => {
    if (paymentSystem && transactionId) {
      sendTransaction(paymentSystem, transactionId, setIsLoading)
    }
  }, [])

  // если по первоначальному пути не получили транзакцию
  // то показывает children (основная загрузка приложения)
  if (!transactionId) {
    return contactStore
  }

  if (isLoading) {
    return <div className={css.loader} />
  }

  return contactStore
}
