import React from 'react'
import ReactDOM from 'react-dom/client'
// local
import { PayNavigator } from 'src/PayNavigator'
// styles
import './index.scss'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <PayNavigator />
  </React.StrictMode>,
)
